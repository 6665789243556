import CookieNotice from "@src/components/CookieNotice";
import { store } from "@src/store";
import { NextPage } from "next";
import type { AppProps } from "next/app";
import { DM_Sans, Inter, Nunito_Sans, Roboto } from "next/font/google";
import Head from "next/head";
import "../styles/globals.css";
import "../components/PrivacyPolicy/privacypolicy.scss";
import { ReactElement, ReactNode } from "react";
import { Toaster } from "react-hot-toast";
import { Provider } from "react-redux";

export type NextPageWithLayout<P = object, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

const nunito_Sans = Nunito_Sans({
  subsets: ["latin"],
  weight: ["400", "500", "600", "700"],
  variable: "--font-family-nunito",
});

const dM_Sans = DM_Sans({
  subsets: ["latin"],
  weight: ["400", "500"],
  variable: "--font-family-dm-sans",
});

const roboto = Roboto({
  subsets: ["latin"],
  weight: ["400", "500"],
  variable: "--font-family-roboto",
});

const inter = Inter({
  subsets: ["latin"],
  weight: ["400", "500", "600", "700"],
  variable: "--font-family-inter",
});

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function App({ Component, pageProps: { session, ...pageProps } }: AppPropsWithLayout) {
  const getLayout = Component.getLayout || (page => page);
  const pageTitle = `${process.env.NEXT_PUBLIC_PLATAFORM_NAME}`;

  return (
    <Provider store={store}>
      <Head>
        <title>{pageTitle}</title>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover"
        />
      </Head>
      <main
        className={`${nunito_Sans.variable} ${dM_Sans.variable} ${inter.variable} ${roboto.variable} font-nunito`}
      >
        <Toaster />
        <CookieNotice />
        {getLayout(<Component {...pageProps} />)}
      </main>
    </Provider>
  );
}

export default App;
