"use client";

import Script from "next/script";
import { FC, useEffect } from "react";

const declareFunction = () => {
  const divCookieBanner = document.getElementById("cookie-banner");
  if (divCookieBanner) {
    divCookieBanner.innerHTML = `
    <style>
      #onetrust-banner-sdk .ot-sdk-row, #onetrust-pc-sdk .ot-sdk-row, #ot-sdk-cookie-policy .ot-sdk-row {
        font-family: 'Inter', sans-serif;
      }
      #onetrust-pc-sdk.otPcCenter {
        border-radius: 16px;
      }
    </style>
    <script type="text/javascript">
      function OptanonWrapper() { }
    </script>`;
  }
};

const CookieNotice: FC = () => {
  useEffect(() => {
    declareFunction();
  }, []);

  return (
    <>
      <Script
        src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
        type="text/javascript"
        charSet="UTF-8"
        id="ot-sdk-js"
        data-domain-script={process.env.NEXT_PUBLIC_PRIVACY_BANNER_DOMAIN_ID}
      ></Script>

      <div id="cookie-banner"></div>
    </>
  );
};

export default CookieNotice;
