import { createApi } from "@reduxjs/toolkit/query/react";
import { Pagination } from "@src/shared/interfaces/pagination";
import { AppDispatch } from "@src/store";
import { User } from "@src/store/slices/login/types";
import { userActions } from "@src/store/slices/users";
import { formatPhone } from "@src/utils/phone";
import { format, isValid } from "date-fns";
import jwtDecode from "jwt-decode";

import { api, baseQueryMiddleware, hasMoreItems, mergePaginationItems } from "./api";
import { infoClient, ipClient } from "./externalApis";

type UserData = {
  name?: string;
  phone?: string;
  document?: string;
  email?: string;
  avatar?: string;
  isActive?: boolean;
  department?: string;
  customer?: string;
};

type PasswordData = {
  password: string;
  token: string;
};

type TermsData = {
  ip: string;
  so: string;
  lat?: number;
  lon?: number;
  emailMkt?: boolean;
  email: string;
  city?: string;
  state?: string;
};

export const createUser = (user: UserData) => async (dispatch: AppDispatch) => {
  const { customer, department, ...newUser } = user;
  const data = { ...newUser, departmentIds: [department], isActive: true };
  try {
    dispatch(userActions.controlView({ isLoading: true, hasError: false }));
    const { status } = await api.post(`/customers/${customer}/users`, data);
    if (status == 200) {
      dispatch(userActions.controlView({ isLoading: false, hasError: false, msgError: "" }));
      return true;
    }
  } catch (err: any) {
    dispatch(
      userActions.controlView({
        isLoading: false,
        hasError: true,
        msgError: err.response.data.message,
      }),
    );
  }
};

export const updateUser = (user: UserData, userId: string) => async (dispatch: AppDispatch) => {
  const { customer, department, ...newUser } = user;
  const data = { ...newUser, departmentIds: [department] };
  try {
    if (customer) {
      dispatch(userActions.controlView({ isLoading: true, hasError: false, msgError: "" }));
      const { status } = await api.put(`/users/${userId}`, data);
      if (status == 200) {
        dispatch(userActions.controlView({ isLoading: false, hasError: false, msgError: "" }));
        return true;
      }
    }
  } catch (err: any) {
    dispatch(
      userActions.controlView({
        isLoading: false,
        hasError: true,
        msgError: err.response.data.message,
      }),
    );
  }
};

export const defineFirstPassword = (data: PasswordData) => async (dispatch: AppDispatch) => {
  try {
    dispatch(userActions.controlView({ isLoading: true, hasError: false }));
    const { status } = await api.post("/auth/define-password", data);
    if (status == 204) {
      dispatch(userActions.controlView({ isLoading: false, hasError: false }));
      return true;
    }
  } catch (err: any) {
    dispatch(userActions.controlView({ isLoading: false, hasError: true }));
  }
};

export const validateToken = (token: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(userActions.controlView({ isLoading: true, hasError: false }));
    const response = await api.post("/auth/validate-token", { token });
    const user = jwtDecode<User>(token);
    dispatch(
      userActions.controlData({
        user: {
          name: user.name,
          email: user.email,
          roles: user.roles,
          privacy: user.privacy,
        },
      }),
    );
    dispatch(userActions.controlView({ isLoading: false, hasError: false }));
    return {
      valid: response?.data.valid,
      privacy: {
        assign: response?.data?.privacy?.assign,
      },
    };
  } catch (err: any) {
    dispatch(userActions.controlView({ isLoading: false, hasError: false }));

    throw err;
  }
};

export const termsAccept = (terms: TermsData) => async (dispatch: AppDispatch) => {
  try {
    dispatch(userActions.controlView({ isLoading: true, hasError: false }));
    const response = await api.post("/privacy", { ...terms });
    dispatch(userActions.controlView({ isLoading: false, hasError: false }));
    return {
      privacy: {
        assign: response?.data?.privacy?.assign,
      },
    };
  } catch (err: any) {
    dispatch(userActions.controlView({ isLoading: false, hasError: false }));

    throw err;
  }
};
export const findClientInfos = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(userActions.controlView({ isLoading: true, hasError: false }));
    const response = await infoClient.request({ method: "get" });

    dispatch(userActions.controlView({ isLoading: false, hasError: false }));
    return {
      city: response?.data?.city,
      state: response?.data?.region_code,
      lat: response?.data?.longitude,
      lon: response?.data?.latitude,
    };
  } catch (err: any) {
    dispatch(userActions.controlView({ isLoading: false, hasError: false }));

    throw err;
  }
};

export const findIp = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(userActions.controlView({ isLoading: true, hasError: false }));
    const response = await ipClient.request({ method: "get" });

    dispatch(userActions.controlView({ isLoading: false, hasError: false }));
    return {
      ip: response?.data?.ip,
    };
  } catch (err: any) {
    dispatch(userActions.controlView({ isLoading: false, hasError: false }));

    throw err;
  }
};

export const usersApi = createApi({
  reducerPath: "usersApi",
  refetchOnMountOrArgChange: true,
  baseQuery: baseQueryMiddleware,
  endpoints: builder => ({
    getUsers: builder.query({
      query: (params: Pagination) => ({
        url: `/users`,
        method: "GET",
        params,
      }),
      transformResponse(baseQueryReturnValue: any, meta, arg) {
        if (baseQueryReturnValue && baseQueryReturnValue.rows) {
          return {
            ...baseQueryReturnValue,
            hasMore: hasMoreItems(baseQueryReturnValue),
            rows: baseQueryReturnValue?.rows?.map((user: any) => {
              const lastLoginDate = user?.lastLogin
                ? isValid(new Date(user.lastLogin))
                  ? format(new Date(user.lastLogin), "dd/MM/yyyy")
                  : null
                : null;
              const createdAtDate = user?.createdAt
                ? isValid(new Date(user.createdAt))
                  ? format(new Date(user.createdAt), "dd/MM/yyyy")
                  : null
                : null;

              return {
                ...user,
                lastLogin: lastLoginDate,
                createdAt: createdAtDate,
                phone: formatPhone(user?.phone),
              };
            }),
          };
        }

        return baseQueryReturnValue;
      },
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: mergePaginationItems,
    }),
    getUser: builder.query({
      query: ({ user, customer }) => ({
        url: `/customers/${customer}/users/${user}`,
        method: "GET",
      }),
    }),
  }),
});

export const { useGetUsersQuery, useLazyGetUsersQuery, useLazyGetUserQuery } = usersApi;
